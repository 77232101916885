<template>
  <b-modal
    ref="accounts-modal"
    centered
    scrollable
    hide-footer
    size="lg"
    title="Select accounts"
    class="p-0"
  >
    <b-container fluid class="ma-0 pa-0" style="height: 70vh;overflow:auto;">
      <accounts
        ref="accounts"
        :selectable="true"
        :show-add-button="false"
        @row-select="onRowSelect"
      />
    </b-container>
    <hr />
    <!--    {{selectedAccounts}}-->
    <form-submission-actions
      :mode="$constants.FORM_MODE.READONLY"
      :hide-regular-buttons="true"
      @custom-click="
        name => {
          this[name]();
        }
      "
      :custom-buttons="[
        {
          text: 'Select',
          icon: 'check',
          loading: false,
          visible: true,
          disabled: !selectedAccounts.length,
          method: 'onSelect',
          variant: 'outline-dark'
        },
        {
          text: 'Close',
          icon: 'times',
          loading: false,
          visible: true,
          disabled: false,
          method: 'onClose',
          variant: 'outline-dark'
        }
      ]"
    />
  </b-modal>
</template>

<script>
import Accounts from "@/views/RelationshipManagement/Accounts";
import FormSubmissionActions from "@/components/FormSubmissionActions";

export default {
  name: "AccountsModal",
  components: {
    FormSubmissionActions,
    Accounts
  },
  data: function() {
    return {
      selectedAccounts: [],
      rawData: {}
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    show() {
      this.selectedAccounts = [];

      this.$refs["accounts-modal"].show();
    },
    onRowSelect() {
      this.selectedAccounts = this.$refs["accounts"].getSelectedAccounts();
    },
    onSelect() {
      //const selected = this.$refs['accounts'].getSelectedAccounts()

      this.$emit("select", this.selectedAccounts);

      this.$refs["accounts-modal"].hide();
    },
    onClose() {
      this.$refs["accounts-modal"].hide();
    }
  }
};
</script>

<style scoped></style>
