var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "accounts-modal",
    staticClass: "p-0",
    attrs: {
      "centered": "",
      "scrollable": "",
      "hide-footer": "",
      "size": "lg",
      "title": "Select accounts"
    }
  }, [_c('b-container', {
    staticClass: "ma-0 pa-0",
    staticStyle: {
      "height": "70vh",
      "overflow": "auto"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('accounts', {
    ref: "accounts",
    attrs: {
      "selectable": true,
      "show-add-button": false
    },
    on: {
      "row-select": _vm.onRowSelect
    }
  })], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.$constants.FORM_MODE.READONLY,
      "hide-regular-buttons": true,
      "custom-buttons": [{
        text: 'Select',
        icon: 'check',
        loading: false,
        visible: true,
        disabled: !_vm.selectedAccounts.length,
        method: 'onSelect',
        variant: 'outline-dark'
      }, {
        text: 'Close',
        icon: 'times',
        loading: false,
        visible: true,
        disabled: false,
        method: 'onClose',
        variant: 'outline-dark'
      }]
    },
    on: {
      "custom-click": function customClick(name) {
        _this[name]();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }