<template>
  <div class="animated fadeIn">
    <b-card>
      <b-row class="p-1">
        <b-col cols="2">
          <font-awesome-icon icon="chevron-down" />
          <h6 v-b-toggle.collapse-wiz-1 style="display: inline;cursor: pointer">
            Activity {{ activity_id }}
          </h6>
        </b-col>
      </b-row>
      <b-collapse id="collapse-wiz-1">
        <activity-submission-form
          :id="activity_id"
          :mode="$constants.FORM_MODE.EDIT"
          action="preview"
        />
      </b-collapse>
      <hr />
      <b-row class="p-1">
        <b-col cols="2">
          <!-- <font-awesome-icon icon="chevron-down" /> -->
          <h6 v-b-toggle.collapse-wiz-2 style="display: inline;cursor: pointer">
            Selected Accounts
          </h6>
        </b-col>
      </b-row>
      <!-- <b-collapse id="collapse-wiz-2"> -->
      <b-row class="pb-2" v-if="dataTable.visible">
        <b-col lg="2">
          <b-button-group>
            <b-button
              variant="outline-dark"
              title="Add Account"
              size="sm"
              @click="addAccount()"
            >
              <font-awesome-icon icon="plus" />
            </b-button>
            <b-button
              variant="outline-dark"
              title="Remove all"
              size="sm"
              @click="removeAllAccounts()"
            >
              <font-awesome-icon icon="trash" />
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <!-- </b-collapse> -->

      <table-custom
        ref="dataTable"
        :name="`custom_table_selected_accounts`"
        :loading="dataTable.isLoading"
        :data="dataTable.dataSet"
        :options="dataTable.options"
      >
        <div slot="custom-actions" slot-scope="props">
          <div class="btn-group">
            <span
              class="btn btn-danger btn-sm"
              @click="removeAccount(props.row.ID)"
            >
              <font-awesome-icon icon="trash"
            /></span>
          </div>
        </div>
      </table-custom>
      <hr />
      <b-progress :value="progressValue" :striped="true" height="2rem" />

      <b-row class="p-1">
        <b-col cols="2">
          <font-awesome-icon icon="chevron-down" />
          <h6
            v-b-toggle.collapse-wiz-log
            style="display: inline;cursor: pointer"
          >
            Progress log
          </h6>
        </b-col>
      </b-row>

      <b-collapse id="collapse-wiz-log">
        <inline-input
          :value="progressLog"
          :rows="5"
          label="Log"
          :hide-label="true"
          :readonly="false"
          :mode="$constants.FORMCONTROLMODE.EDIT"
          :is-text-area="true"
        />
      </b-collapse>

      <hr />
      <form-submission-actions
        :mode="$constants.FORM_MODE.READONLY"
        @back="$router.push($store.getters['router/previousRoute'])"
        @custom-click="
          name => {
            this[name]();
          }
        "
        :custom-buttons="[
          {
            text: 'Start',
            icon: 'play',
            loading: inProgress,
            visible: true,
            disabled: !readyToStart,
            method: 'onStart',
            variant: 'outline-dark'
          }
        ]"
      />
    </b-card>
    <accounts-modal ref="accounts-modal" @select="onAccountsSelect" />
  </div>
</template>

<script>
import moment from "moment";
import FormSubmissionActions from "@/components/FormSubmissionActions";
import InlineInput from "@/components/InlineInput";
import TableCustom from "@/components/TableCustom";
import ActivitySubmissionForm from "./ActivitySubmissionForm.vue";
import AccountsModal from "./AccountsModal.vue";

export default {
  props: {
    activity_id: {
      type: [String, Number],
      default: ""
    }
  },
  name: "BatchActivitySubmissionWizard",
  components: {
    FormSubmissionActions,
    TableCustom,
    ActivitySubmissionForm,
    AccountsModal,
    InlineInput
  },
  data: function() {
    return {
      inProgress: false,
      progressLog: "",
      progressValue: 0,
      showAccountsModal: false,
      rawData: {},
      dataTable: {
        visible: true,
        isLoading: false,
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          filterByColumn: true,
          columns: [
            "ID",
            "Account Name",
            "Phone",
            "Email",
            "Website",
            "Category",
            "Assigned To",
            "Country",
            "City",
            "State",
            "Created",
            "Modified",
            "Last Activity Date",
            "Last Activity",
            "Actions"
          ],
          filterable: [
            "ID",
            "Account Name",
            "Phone",
            "Email",
            "Website",
            "Category",
            "Assigned To",
            "Country",
            "State",
            "City",
            "Created",
            "Modified",
            "Last Activity Date",
            "Last Activity"
          ],
          perPage: 50,
          perPageValues: [],
          showCustomActions: true,
          showChildRows: false,
          selectableRows: false
        },

        dataSet: [],
        onRowClick: function() {},
        totalRecords: 0
      }
    };
  },
  computed: {
    readyToStart() {
      return this.dataTable.dataSet.length > 0;
    }
  },
  async mounted() {
    if (!this.activity_id) await this.$form.msgBoxOk("Activity is undefined");
  },
  methods: {
    addAccount() {
      this.$refs["accounts-modal"].show();
    },
    removeAllAccounts() {
      this.dataTable.dataSet = [];
    },
    removeAccount(_id) {
      this.dataTable.dataSet = this.dataTable.dataSet.filter(
        i => i["ID"] !== _id
      );
    },
    onAccountsSelect(e) {
      for (let i = 0; i < e.length; i++) {
        e[i]["#"] = false;
      }

      let ne = e.filter(
        i => !this.dataTable.dataSet.find(d => d["ID"] === i["ID"])
      );

      this.dataTable.dataSet = [...this.dataTable.dataSet, ...ne];
    },
    async onStart() {
      let confirm = await this.$form.showConfirmation(
        `Activity #${this.activity_id} will be copied to selected accounts. Do you want to proceed?`
      );

      if (!confirm) return;

      this.progressValue = 0;

      const step = 100 / this.dataTable.dataSet.length;

      this.inProgress = true;

      //this.progressLog += moment().format('YYYY-MM-DD h:mm:ss')+': started\r\n'

      for (const row of this.dataTable.dataSet) {
        this.progressValue += step;

        const timestamp =
          moment().format("YYYY-MM-DD h:mm:ss") + ": " + row["Account Name"];

        this.progressLog += timestamp + " in progress\r\n";

        let response = await this.$api.put(
          `activities/${this.activity_id}/copy`,
          {
            account_id: row["ID"]
          }
        );

        if (response["id"]) {
          this.progressLog = this.progressLog.replace(
            timestamp + " in progress",
            timestamp + " success. " + response["url"]
          );
        } else if (!response["id"] && response["message"]) {
          this.progressLog = this.progressLog.replace(
            timestamp + " in progress",
            timestamp + " failed. " + response["message"]
          );
        } else if (!response) {
          this.progressLog = this.progressLog.replace(
            timestamp + " in progress",
            timestamp + " failed. Response is undefined"
          );
        }

        //this.progressLog += row['ID'] + ' success\r\n'

        //console.log(response );
      }

      //this.progressLog += moment().format('YYYY-MM-DD h:mm:ss')+': completed\r\n'

      this.inProgress = false;

      this.progressValue = 0;
    }
  }
};
</script>

<style scoped></style>
